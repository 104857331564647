* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
  transition: 0.4 ease all;
  outline: hidden;
}

::-webkit-scrollbar {
  cursor: pointer;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: blueviolet;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(137, 43, 226, 0.548);
}

html{
  font-size: 16px;
}

button, img {
  cursor: pointer;
}

button > * {
  cursor: pointer;;
}

button  {
  margin: 0;
  padding: 0;
  border: none;
  background-color: var(--darkPanel) ;
  color: var(--lighttext);
}

a:hover, button:hover {
  opacity: 0.8;
}

.popup-content {
 border: none !important;
 background: none !important;
 padding: 0;
}

:root{
  --darkPanel : blueviolet ;
  --lighttext : white;
  --darktext :  #222225;
  --lighterDark : #383838;
  --darkerLight : #e4e4e4;
}
