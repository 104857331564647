.modal {
    padding: 0.5rem;
    border: none;
    background-color:black;
    color: var(--lighttext);
    position: relative;
}

.close {
    position: absolute;
    right: 0;
    top: 0px;
    width: 40px;
    height: 40px;
    background-color: var(--darkPanel);
    color: var(--lighttext);
    outline:hidden;
}

.heading {
    font-size: 2rem;
    padding: 1rem 2rem;
}

.header {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
}

.content {
    padding: 0rem 2rem;
    line-height: 1.5rem;
}

.actions {
    display: flex;
    margin: 1rem 2rem;
    padding: 1rem;
    color: white;
    width: 90%;
    align-items: center;
    justify-content:center;
}

/* Media Queries */
@media screen and (max-width: 600px) {
    .popup-content {
        width: 90%;
    }
}