/* Leftmost selection Bar */
@import url(https://fonts.googleapis.com/css?family=JetBrains+Mono:100,200,300,regular,500,600,700,800,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic);

.select__bar {
  background-color: var(--darkPanel);
  min-height: 100vh;
  display: flex;
  min-width: 80px;
  max-width: 80px;
  padding-top: 6.25rem;
  align-items: center;
  flex-direction: column;
}

.select__bar>img {
  margin-top: 1.875rem;
}

/* Main Component */

.main__container {
  width: 100%;
  display: flex;
  height: 100vh;
  background-color: black;
  transition: 0.2s ease;
}

.main__container_light-mode {
  background-color: #a5aaa99f;
}

.right__container,
.left__container {
  flex-basis: 50%;
  border: 0.3px solid rgba(167, 167, 167, 0.39);
  height: 100%;
}

.content__area {
  display: flex;
  margin-top: 5rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  width: 100%;
  height: auto;
}

/* Left container */

.header__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--darkPanel);
  color: var(--lighttext);
}

.file__name {
  padding: 0.625rem;
}

.btn {
  border: none;
  padding: 0.5rem 1rem;
}

.btn_label {
  margin-left: 0.1rem;
}

.btn_wt_icon {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.25rem;
  justify-content: space-between;
  margin-left: 0.5rem;
  min-width: 5rem;
  background-color: var(--darkPanel);
  background: rgba(255, 255, 255, 0.09);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.code__body {
  color: white;
  margin: 1rem 0.625rem;
  min-height: 70vh;
  max-height: 60%;
  background-color: var(--lighterDark);
  transition: 0.2s ease;
}

.code__body_light-mode {
  background-color: var(--darkerLight);
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.875rem;
}

/* ----- codemirror classes ----- */

.CodeMirror {
  min-height: 40vh;
  max-height: 45vh;
}

.CodeMirror * {
  font-family: 'JetBrains Mono', monospace;
  font-size: 15px;
}

.code-mirror-wrapper {
  width: 94%;
}

/* --------- */

.input__block {
  padding: 0.625rem;
  font-size: 18px;
  background-color: var(--lighterDark);
  resize: none;
  color: white;
  width: 94%;
  min-height: 40vh;
  max-height: 40vh;
  outline: none;
  transition: 0.2s ease;
  font-family: 'JetBrains Mono', monospace;
}

.code__block_light-mode {
  background-color: var(--darkerLight);
  color: var(--darktext);
}

.input__block {
  min-height: 8vh;
  max-height: 8vh;
  margin-top: 1rem;
  background-color: rgba(10, 5, 5, 0.397);
  color: rgb(11, 165, 11);
  transition: 0.2s ease;
}

.input__block_light-mode {
  background-color: rgba(99, 102, 104, 0.281);
  color: var(--darktext);
}

.logger__head_left {
  display: flex;
  padding: 0.225rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  background-color: var(--darkPanel);
}

.logger__head_left > .tooltipBoundary {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
}

.logger__heading {
  padding: 0.5rem;
}

.output__logger {
  border-radius: 3px;
  margin: 1.25rem;
  background-color: var(--lighterDark);
  color: var(--lighttext);
  min-height: 50vh;
  position: relative;
}

.logger__head_right {
  position: relative;
  overflow-x: auto;
  border-radius: 3px;
  margin: 1.25rem;
  background-color: var(--lighterDark);
  height: 70vh;
  max-height: max-content;
  margin-top: 1.5rem;
  transition: 0.2s ease;
}

.logger__head_right_light-mode {
  background-color: var(--darkerLight);
}

.output {
  color: var(--lighttext);
  font-size: 18px;
  padding: 0.563rem;
  transition: 0.2s ease;
  height: 100%;
}

.output>pre {
  text-wrap: wrap;
  white-space: pre-wrap;
}

.output_light-mode {
  color: var(--darktext);
}

.loader {
  position: absolute;
  top: 40%;
  left: 40%;
}

.d-flex{
  display: flex;
}
/* Media Queries */

@media screen and (max-width: 600px) {

  body {
    background-color: black;
    overflow-x: hidden;
  }

  .main__container {
    display: flex;
    flex-direction: column;
  }

  .select__bar {
    background-color: var(--darkPanel);
    justify-content: center;
    flex-direction: row;
    min-width: 100%;
    min-height: 0.8rem;
    padding: 3rem;
    margin: 0px;
    margin-top: 2.2rem;
  }

  .select__bar>img {
    width: 30px;
    margin-right: 2rem;
  }

  .content__area {
    display: flex;
    min-width: 80%;
    margin: 0px;
    margin-top: 1rem;
    flex-direction: column;
  }

  .left__container,
  .right__container {
    min-width: 85vw;
    margin: 0.3rem;
    margin-bottom: 1rem;
  }

  .code__body {
    margin-bottom: 2rem;
  }

  .logger__head_right {
    margin: 1rem;
    height: 50vh;
  }

  .logger__heading {
    font-size: 1rem;
  }

  .loader {
    position: absolute;
    left: 35%;
    top: 30%;
  }

  .CodeMirror {
    max-height: 40vh;
  }

  /* ----- codemirror class ----- */

  .CodeMirror * {
    font-size: 13px;
  }

  /* --------- */

}