/* Top Navigation */
.top__nav {
  display: flex;
  background-color: #eee;
  position: fixed;
  min-height: 70px;
  padding: 0rem 1.25rem;
  margin-bottom: 6.25rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}

.info__section {
  width: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info__section > img{
  margin-left: 0.625rem;
}

.logo__info {
  display: flex;
  align-items: center;
}

#logo__name {
  margin-left: 0.625rem;
}

/* Media Queries */
@media screen and (max-width: 600px) {
  #logo__name {
    font-size: 0.9rem;
  }
}
